/* Ensure Tailwind CSS utilities take precedence */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */
body {
  background: linear-gradient(to right, #000000, #111122, #1a1a33); /* Black to dark subtle blue/purple gradient */
  color: #E3DAC9; /* Bone white text */
  font-family: 'Fira Code', monospace;
  margin: 0; /* Ensure no margin around the body */
  padding: 0; /* Ensure no padding around the body */
  min-height: 100vh; /* Ensure the body takes at least the full viewport height */
}

h1, h2, h3, h4, h5, h6 {
  color: #E3DAC9; /* Bone white for headings */
}

a {
  color: #3498db; /* Blue color for links */
}

a:hover {
  text-decoration: none; /* Remove underline on hover */
}

/* Custom gradient text for Zachary Nobunaga */
.gradient-text {
  background: linear-gradient(to right, #d3959b, #8ca6db);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block; /* Ensure it only applies to the text */
}

/* Custom description text color */
.text-description {
  color: #B0B0B0; /* Smokey white/grey */
}

/* Projects container for vertical scroll */
.projects-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  padding: 16px 0;
  justify-content: flex-start;
  max-height: 600px; /* Adjust height based on your needs */
}

.projects-container::-webkit-scrollbar { /* WebKit */
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.project-box {
  flex: 0 0 260px; /* Adjusted height for shorter boxes */
  max-height: 260px; /* Ensure it doesn't exceed the container */
  border: 1px solid #4A5568; /* Gray border */
  border-radius: 8px;
  padding: 16px; /* Adjust padding for spacing */
  background-color: #1A202C; /* Dark background for project box */
  scroll-snap-align: start; /* Snap to start */
  margin-bottom: 16px; /* Space between project boxes */
  box-sizing: border-box;
  width: 360px; /* Adjusted width */
  word-wrap: break-word; /* Ensure text wraps within the box*/
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .project-box {
    flex: 0 0 220px;
    max-height: 220px;
    width: 320px; /* Adjusted width for smaller screens */
  }
}

@media (max-width: 768px) {
  .project-box {
    flex: 0 0 200px;
    max-height: 200px;
    width: 300px; /* Adjusted width for smaller screens */
  }
}

@media (max-width: 640px) {
  .project-box {
    flex: 0 0 180px;
    max-height: 180px;
    width: 280px; /* Adjusted width for smaller */
  }
}

/* Styles for the toolbar */
.toolbar {
  position: fixed;
  top: 4rem; /* Adjusted top to leave some space */
  left: 12rem; /* Stops before touching the navbar */
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: flex-start;
  z-index: 50;
  background: linear-gradient(to right, #000000, #111122, #1a1a33); /* Match background */
  width: auto;
}

.toolbar-item {
  background-color: #252526;
  color: #E3DAC9; /* Bone white text */
  margin-right: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  display: inline-block;
  white-space: nowrap;
}

.toolbar-item:hover {
  background-color: #373737;
  color: #3498db; /* Blue color on hover */
}

/* Styles for the navbar */
nav {
  background-color: #2d3748; /* Ensures navbar has the same background color */
}

/* Styles for the TextImage component */
.text-image-container {
  position: fixed;
  top: 0;
  right: 0;
  margin: 1rem;
  z-index: 100; /* Ensure it appears on top */
}

.text-image {
  width: 150px; /* Adjust the width as needed */
  height: auto;
  opacity: 0.7; /* Slight transparency for visual appeal */
  border: 1px solid #2d3748; /* Border color matching the navbar */
  border-radius: 4px; /* Slightly rounded corners */
  position: relative;
}

.text-image:hover {
  opacity: 1; /* Full opacity on hover */
}

.highlight {
  position: absolute;
  background-color: rgba(52, 152, 219, 0.5); /* Blue with transparency */
  width: 100%;
  height: 2px; /* Height of the highlight line */
  pointer-events: none; /* Ensure it does not interfere with mouse events */
}

/* Custom media query for hiding the text image on smaller screens!  /
@media (max-width: 1024px) {
  .text-image-container {
    display: none;
  }
}

/* Basic styles for the 404 page */
.bg-black {
  background-color: #000;
}

.text-bone-white {
  color: #E3DAC9;
}

.min-h-screen {
  min-height: 100vh;
}

.bg-blue-500 {
  background-color: #3498db;
}

.hover\:bg-blue-700:hover {
  background-color: #2980b9;
}

.font-bold {
  font-weight: 700;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.rounded {
  border-radius: 0.25rem;
}

.mt-4 {
  margin-top: 1rem;
}

/* Additional padding for the contact section on smaller screens */
.contact-container {
  padding-bottom: 2rem; /* Default padding for larger screens*/
}

@media (max-width: 768px) {
  .contact-container {
    padding-bottom: 5rem; /* Increased padding for smaller screens */
  }
}

/* Add a max-width and center alignment for wider screens */
.container {
  max-width: 1800px; /* Restrict maximum width */
  margin: 0 auto; /* Center the content */
  padding: 0 16px; /* Add padding for breathing room */
}

/* Ensure the content scales well across all screen sizes */
@media (min-width: 1800px) {
  .flex-grow {
    margin-left: auto;
    margin-right: auto;
  }
}

/* Keep toolbar and navbar hidden on smaller screens!! */
@media (max-width: 768px) {
  .toolbar,
  nav {
    display: none;
  }
}

